import {
  Container,
  makeStyles,
  Grid,
  Link as MUILink,
  Box,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Image from 'next/image'
import React from 'react'

import Link from '@aletheia/common/components/Link'

import { GWWC_DONATE_FULL_URL } from '../../globals/routes'
import { SansSerifTypography } from '../../globals/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '160px',
    marginTop: theme.spacing(6),
    padding: theme.spacing(3),
    '& a': {
      color: '#FFF',
    },
    '&:hover img': {
      filter: 'grayscale(0)',
    },
  },
  wrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  logoSectionDescription: {
    marginTop: theme.spacing(2),
  },
  logoContainer: {
    flexGrow: 1,
    '& img': {
      filter: 'grayscale(1)',
      transition: 'filter .3s ease-in-out',
    },
  },
  nav: {
    '& a': {
      display: 'block',
      fontSize: '1.1rem',
    },
  },
}))

const Footer: React.FC = () => {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <Container fixed>
        <Grid container className={classes.wrapper}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={6}>
                <nav aria-label="footer main" className={classes.nav}>
                  <SansSerifTypography>
                    <Link href="/funds">
                      Funds <ChevronRightIcon />
                    </Link>
                    <Link href="/about-us">
                      About
                      <ChevronRightIcon />
                    </Link>
                    <Link href="/blog">
                      Blog
                      <ChevronRightIcon />
                    </Link>
                    <Link href="/contact">
                      Contact
                      <ChevronRightIcon />
                    </Link>
                    <MUILink href={GWWC_DONATE_FULL_URL}>
                      Donate
                      <ChevronRightIcon />
                    </MUILink>
                  </SansSerifTypography>
                </nav>
              </Grid>
              <Grid item xs={6}>
                <nav aria-label="footer secondary" className={classes.nav}>
                  <SansSerifTypography>
                    <Link href="/faq">
                      FAQ
                      <ChevronRightIcon />
                    </Link>
                    <Link href="/stats">
                      Our Stats
                      <ChevronRightIcon />
                    </Link>
                    <Link href="/grants">
                      Grants Database
                      <ChevronRightIcon />
                    </Link>
                    <Link href="/team">
                      Team
                      <ChevronRightIcon />
                    </Link>
                    <MUILink
                      href="https://docs.google.com/document/d/1C9yEI4Lj_OgY87xJ57IWC1RvEcYOaQHK0TiSIczaMJg/edit?tab=t.0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      EV Grants Cancellation Policy
                      <ChevronRightIcon />
                    </MUILink>
                    <Link href="/awards-policy">
                      Awards Policy
                      <ChevronRightIcon />
                    </Link>
                  </SansSerifTypography>
                </nav>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign="right" sx={{ mb: 4 }}>
              <Image
                src="/logos/EA Funds.svg"
                alt="EA Funds Logo"
                width={177}
                height={32}
              />
            </Box>
            <Box textAlign="right">
              <SansSerifTypography variant="body2" gutterBottom>
                EA Funds is an{' '}
                <MUILink
                  href="https://www.ev.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>Effective Ventures</strong>
                </MUILink>{' '}
                project. Effective Ventures Foundation (UK) (EV UK) is a charity
                in England and Wales (with registered charity number 1149828,
                registered company number 07962181, and is also a Netherlands
                registered tax-deductible entity ANBI 825776867). Effective
                Ventures Foundation USA Inc. (EV US) is a section 501(c)(3)
                organization in the USA (EIN 47-1988398). EV UK and EV US are
                separate charities that collaborate closely under the “Effective
                Ventures” name.
                <br />© EA Funds 2017-
                {new Date().getFullYear()}
              </SansSerifTypography>
            </Box>
          </Grid>
        </Grid>
        <Box textAlign={'center'} sx={{ mb: 4 }}>
          <SansSerifTypography>
            <MUILink href="/privacy" target="_blank">
              Privacy Policy
            </MUILink>{' '}
            |{' '}
            <MUILink href="/terms" target="_blank">
              Terms and Conditions
            </MUILink>{' '}
            |{' '}
            <MUILink
              href="https://docs.google.com/document/d/1fSN-VoZLgy1U676-vuLK_fOL6mcQ_K1qGWXzlBhQJg0/edit?usp=sharing"
              target="_blank"
            >
              Conflict of Interest Policy
            </MUILink>{' '}
            |{' '}
            <MUILink
              href="https://www.effectivealtruism.org/cookie-notice"
              target="_blank"
            >
              Cookie notice
            </MUILink>
          </SansSerifTypography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Grid container spacing={2} className={classes.logoContainer}>
              <Grid item xs={3}>
                <MUILink
                  href="https://www.openphilanthropy.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/Open Philanthropy.svg"
                    alt="Open Philanthropy Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
            </Grid>
            <SansSerifTypography
              variant="body2"
              className={classes.logoSectionDescription}
            >
              We collaborate and receive additional funding from Open
              Philanthropy.
            </SansSerifTypography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container spacing={2} className={classes.logoContainer}>
              <Grid item xs={3}>
                <MUILink
                  href="https://www.ox.ac.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/University Of Oxford.svg"
                    alt="University Of Oxford Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
              <Grid item xs={3}>
                <MUILink
                  href="https://www.fhi.ox.ac.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/Future of Humanity Institute.svg"
                    alt="Future of Humanity Institute Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
              <Grid item xs={3}>
                <MUILink
                  href="https://globalprioritiesinstitute.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/Global Priorities Institute.svg"
                    alt="Global Priorities Institute Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
            </Grid>
            <SansSerifTypography
              variant="body2"
              className={classes.logoSectionDescription}
            >
              We&apos;re affiliated with the Future of Humanity Institute and
              the Global Priorities Institute at the University of Oxford.
            </SansSerifTypography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container spacing={2} className={classes.logoContainer}>
              <Grid item xs={3}>
                <MUILink
                  href="https://www.ycombinator.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/Y Combinator.svg"
                    alt="Y Combinator Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
            </Grid>
            <SansSerifTypography
              variant="body2"
              className={classes.logoSectionDescription}
            >
              EA Funds was created by the Centre for Effective Altruism during
              the W17 batch of Y Combinator, the world’s leading startup
              incubator.
            </SansSerifTypography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container spacing={2} className={classes.logoContainer}>
              <Grid item xs={3}>
                <MUILink
                  href="https://www.ev.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/Effective Ventures.jpg"
                    alt="Effective Ventures Foundation Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
              <Grid item xs={3}>
                <MUILink
                  href="https://www.givewell.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/Give Well.svg"
                    alt="Give Well Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
              <Grid item xs={3}>
                <MUILink
                  href="https://www.givingwhatwecan.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/logos/Giving What We Can.svg"
                    alt="Giving What We Can Logo"
                    width={76}
                    height={76}
                  ></Image>
                </MUILink>
              </Grid>
            </Grid>
            <SansSerifTypography
              variant="body2"
              className={classes.logoSectionDescription}
            >
              We&apos;re part of the Effective Ventures Foundation, and work
              closely with GiveWell, and Giving What We Can.
            </SansSerifTypography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
